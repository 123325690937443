
.yllw_bnnr {
    flex-basis: 100%;
    flex-grow: 0;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #64a3ca;
    padding: 4px;
    margin-top: -26px;
    margin-bottom: -10px;
    color: #222831;
    font-size: 14px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
}
    @media screen and (max-width: 600px) {
      .yllw_bnnr {
        display: none !important;
    }
    b {
      font-size: 13px;
    }
    span {
      font-weight: 600;
    }
  }
.top_sider_main{
  position: relative;
  width: 100%;
  max-width: 500px;
  padding-bottom: 30px;
}
.top_sider_main img{
  max-width: 100%;
  border-radius: 15px;
  overflow: hidden;
}
.top_sider_main .slick-prev, .slick-next{
  z-index: 1;
}
.top_sider_main .slick-prev {
  left: 5px;
}
.top_sider_main .slick-next {
  right: 5px;
}
.slider_innr_pernt{
  position: relative;
}
.slider_innr_pernt button{
  border: none;
  background: #ffffff3b;
  border-radius: 5px 15px 5px 10px;
  position: absolute;
  padding: 7px 12px;
  right: -2px;
  top: -2px;
}

.soonlabel {
    position: absolute;
    right: 20px;
    top: 5px;
    display: block;
    font-size: 11px;
    font-weight: 800;
    background-color: rgb(0, 209, 118);
    border-radius: 8px;
    border: 2px solid rgb(0, 209, 118);
    padding: 2px 4px;
    color: white;
    box-shadow: rgb(0 209 118) 0px 0px 15px;
}